import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  IconButton,
  useMediaQuery,
  Stack,
  Paper,
  ClickAwayListener,
  Tooltip,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Divider,
  Popper,
  Box,
  Skeleton,
  useTheme,
  Avatar
} from '@mui/material';
import { IconCircleCheck, IconUser, IconUserCog } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { StyledRenderHtmlBox } from 'components/InitiativeInfoSection/InitiativesInfoBox';
import { MainCard, Transitions } from '.';
import { notificationType } from 'utils/constants/globalConstant';
import { formatDateTime } from 'utils/dateResolvers';

const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const NotificationPopper = ({
  openNotification,
  anchorElNotification,
  handleCloseNotificationPopper,
  getAllNotificationsData,
  getAllNotificationsLoading,
  setOpenNotification
}) => {
  const [read, setRead] = useState(2);
  const theme = useTheme();
  const navigate = useNavigate();

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Popper
      placement={matchesXs ? 'bottom' : 'bottom-end'}
      open={openNotification}
      anchorEl={anchorElNotification}
      role={undefined}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [matchesXs ? -5 : 0, 9]
            }
          }
        ]
      }}
      sx={{
        boxShadow: `0px 4px 15px 0px ${theme.palette.primary.lighter} !important`
      }}
    >
      {({ TransitionProps }) => (
        <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
          <Paper
            sx={{
              width: '100%',
              minWidth: 485,
              maxWidth: 620,
              [theme.breakpoints.down('md')]: {
                maxWidth: 285
              }
            }}
          >
            <ClickAwayListener onClickAway={handleCloseNotificationPopper}>
              <MainCard
                title={<Typography sx={{ fontSize: '20px !important', color: 'primary.main' }}>Notification</Typography>}
                elevation={0}
                border={false}
                content={false}
                secondary={
                  <>
                    {read > 0 && (
                      <Tooltip title="Mark as all read">
                        <IconButton color="success" size="small" onClick={() => setRead(0)}>
                          <IconCircleCheck size={20} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              >
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    '& .MuiListItemButton-root': {
                      py: 0.5,
                      '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                  }}
                >
                  {!getAllNotificationsLoading
                    ? getAllNotificationsData?.resultList?.map((notification) => (
                        <Box key={notification.uuid}>
                          <ListItemButton selected={read > 0}>
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  color: 'success.main',
                                  bgcolor: 'success.lighter'
                                }}
                              >
                                {notification?.type === notificationType.ADMINISTRATOR ? <IconUserCog /> : <IconUser />}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                  <Typography variant="h6" fontWeight={'500'}>
                                    {notification?.title}
                                  </Typography>
                                  <Typography variant="body1" color={'grey'}>
                                    {formatDateTime(notification?.createdAt)}
                                  </Typography>
                                </Stack>
                              }
                              secondary={<StyledRenderHtmlBox dangerouslySetInnerHTML={{ __html: notification?.content || '' }} />}
                            />
                          </ListItemButton>
                          <Divider />
                        </Box>
                      ))
                    : [1, 2, 3].map((item) => (
                        <Stack key={item} direction={'row'} gap={1} padding="10px 20px">
                          <Skeleton sx={{ width: 40, height: 40 }} variant="circular" height={40} />
                          <Stack width={'100%'} gap={1}>
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={10} />
                          </Stack>
                        </Stack>
                      ))}
                  <ListItemButton
                    sx={{ textAlign: 'center', py: `${12}px !important` }}
                    onClick={() => {
                      setOpenNotification(false);
                      navigate('/notification-management');
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="primary">
                          View All
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </MainCard>
            </ClickAwayListener>
          </Paper>
        </Transitions>
      )}
    </Popper>
  );
};

NotificationPopper.propTypes = {
  openNotification: PropTypes.bool,
  anchorElNotification: PropTypes.bool,
  handleCloseNotificationPopper: PropTypes.func,
  getAllNotificationsData: PropTypes.array,
  getAllNotificationsLoading: PropTypes.bool,
  setOpenNotification: PropTypes.func
};

export default NotificationPopper;
